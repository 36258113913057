import { createApp } from "vue";
// 导入elemen-plus全部组件
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
//导入图标
// 导入element-plus全部组件样式
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import "./assets/gloable.css";
import "./assets/index.css";
import "./assets/fonts/iconfont.css";
// createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
import axios from 'axios'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate' //引入持久化插件
import QuitOperation from "./utils/quitOperation";

import {createPinia} from "pinia";
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App);
app.use(QuitOperation);
axios.defaults.baseURL = '/api'
axios.defaults.withCredentials = true
app.config.globalProperties.$axios = axios

app.use(pinia).use(router).use(ElementPlus, { locale: zhCn }).mount('#app');