<template>
  <div class="login">
    <!-- 背景图 -->
    <div class="login-img">
      <el-card class="box-card">
        <!-- 标题 -->
        <div slot="header" class="clearfix">
          <h2>污染溯源分析平台（Saas）</h2>
        </div>
        <!-- 登录表单 -->
        <div class="login-input">
          <el-form ref="loginForm" :model="loginForm" :rules="rules">
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" placeholder="请输入用户名">
                <template #prefix>
                  <el-icon class="iconfont icon-RectangleCopy"></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                @focus="showPassword = true"
                placeholder="请输入密码"
              >
                <template #prefix>
                  <el-icon class="iconfont icon-RectangleCopy1"></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <!-- <div class="rmber-me">
                  <el-checkbox v-model="checked1" label="记住我" size="large" @change="handleRememberMe"/>
                  </div> -->
            <el-form-item>
              <el-button type="primary" @click="login">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 登录按钮 -->
        <el-row class="bot-login">
          <el-col :span="9" class="smalltext"
            ><small>其他登录方式：</small></el-col
          >
          <el-col :span="15">
            <el-button type="success" circle>
              <i class="iconfont icon-weixin"></i
            ></el-button>
            <el-button type="primary" circle>
              <i class="iconfont icon-phone"></i
            ></el-button>
          </el-col>
        </el-row>
        <div class="go-zhuce">
          <el-link :underline="false">没有账号？去注册</el-link>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { ArrowDown } from "@element-plus/icons-vue";
import md5 from "md5";
// import { reactive, ref } from "vue";
export default {
  name: "App",
  components: {
    ArrowDown,
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        showPassword: false,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 12, message: "用户名长度应为 2 到 12 个字符", trigger: "blur" },
         // { pattern: /^[a-zA-Z0-9_]+$/, message: "用户名只能包含字母、数字和下划线", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 80, message: "密码长度应为 5 到 80 个字符", trigger: "blur" },
         // { pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/, message: "密码应至少包含一个大写字母、一个小写字母和一个数字", trigger: "blur" }
        ],
      },
      passwordError: false, // 新增：用于标记密码错误
      usernameError: false, // 新增：用于标记用户名错误
    };
  },
  methods: {
    // 保存链接的激活状态
    saveActiveNav(activePath) {
        sessionStorage.setItem("activePath", activePath);
        this.activePath = activePath;
      },
    handleRememberMe() {
      // 用户点击 checkbox 时存储用户名和密码到 localStorage
      if (
        this.rememberMe &&
        this.loginForm.username &&
        this.loginForm.password
      ) {
        localStorage.setItem("username", this.loginForm.username);
        localStorage.setItem("password", this.loginForm.password);
      } else {
        // 清除 localStorage 中的用户名和密码
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      }
    },
    autoFillForm() {
      // 从 localStorage 中读取用户名和密码并自动填充到表单中
      this.loginForm.username = localStorage.getItem("username") || "";
      this.loginForm.password = localStorage.getItem("password") || "";
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 验证通过，执行登录逻辑
          // 这里可以发送登录请求到后端，根据返回结果处理登录状态
          if (valid) {
                    let payload = {
                        name: this.loginForm.username,
                        passwd: md5(this.loginForm.password)
                    };
                    this.loginLoading = true;
                    this.$axios.post("/logon", payload)
                   .then((res) => {
                        console.log(JSON.stringify(res.data))
                        console.log(sessionStorage)
                        if (res.data.extra.message=="success") {
                            sessionStorage.setItem("user", `${res.data.data.name}`);
                            sessionStorage.setItem("token", `loginStatus=${res.data.data.name}`);
                            sessionStorage.setItem("features", `${res.data.data.features}`);
                            this.$router.push("/Alllist/af642b71-f902-4a61-9919-589c1f596fc9");
                        } else {
                            // 新增：如果登录失败，显示相应的错误提示
                            if (res.data.extra.message === "用户名错误") {
                              this.usernameError = true;
                            } else if (res.data.extra.message === "密码错误") {
                              this.passwordError = true;
                            }
                            this.$message.error(res.data.extra.message);
                            this.loginLoading = false;
                        }
                    })
                   .catch((err) => {
                        this.$message.error("服务器连接失败，请稍后重试......");
                        this.loginLoading = false;
                    });
                } else {
                    return false;
                }
          // this.$router.push("/Alllist");
          // alert("提交!");
          // console.log("登录成功");

          // 如果登录成功且用户选择了“记住我”，则将用户名和密码存储到 localStorage
          if (this.rememberMe) {
            localStorage.setItem("username", this.loginForm.username);
            localStorage.setItem("password", this.loginForm.password);
          }
        } else {
          // 验证失败，显示错误信息
          console.log("验证失败");
          return false;
        }
      });
    },
  },
};
</script>
