import {defineStore} from 'pinia';

const UseTabStore = defineStore({
    id: 'UseTabStore',
    state: () => ({
        info: 'pinia 可以使用',
        tabData:{
            active:"af642b71-f902-4a61-9919-589c1f596fc9",
            tabDataArr:[
                { id: "af642b71-f902-4a61-9919-589c1f596fc9", title: "首页", path: "/Alllist", key: 0 }
              ]
        }
    }),
    actions: {  
        addTab(newTabItem)  {  
          const key = newTabItem.id
          this.tabData.tabDataArr.push(newTabItem);  
          this.tabData.active = key;    
          // this.persistState(); // 保存状态
        }, 
        removeTab(router,id) {  

            this.tabData.tabDataArr = this.tabData.tabDataArr.filter(tab => tab.id !== id);  
            // 切换到其他标签页的逻辑  
          
            if (this.tabData.tabDataArr.length > 0) {  
                // console.log(this.tabData.tabDataArr[this.tabData.tabDataArr.length-1])
              this.tabData.active = this.tabData.tabDataArr[this.tabData.tabDataArr.length-1].id
              router.push(`${this.tabData.tabDataArr[this.tabData.tabDataArr.length-1].path}/${id}`);
            }  
            // this.persistState(); // 保存状态
          },  
          setActiveTab(route,path,key) {  
            
            const router = route
            this.tabData.active  = key;  
            // console.log('dddddddddddddddd',this.tabData.active);
            router.push(path);
            // this.persistState(); // 保存状态
          },  
           // 添加保存状态的方法
    // persistState() {
    //   localStorage.setItem('tabData', JSON.stringify(this.tabData));
    // },
    persist: true,}
});
export default UseTabStore;

