<template>
  <div class="common-layout">
    <el-container>
      <el-aside>
        <Nav @tell="receive"></Nav>
      </el-aside>
      <el-main>
        <div class="tabbox">
          <el-tabs @tab-click="clickItem" v-model="activeTabKey" type="card" closable @tab-remove="removeTab">
            <el-tab-pane v-for="item in tabData" :key="item.id" :label="item.title" :name="item.id">
            </el-tab-pane>
          </el-tabs>
          <el-icon @click="removeAllTabs" v-if="tabData.length > 2" class="iconfont icon-roundclosefill close"></el-icon>
        </div>
        <router-view v-slot="{ Component, route }">
          <keep-alive :include="[...cacheRoutes]">
            <component :is="formatComponent(Component, route)" />
          </keep-alive>
        </router-view>

      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, toRef, computed ,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import Nav from "@/components/common/navmenu.vue";
import { ElMessage } from "element-plus";
import UseTabStore from '../../store/index';
import { h } from 'vue';
import { userFeature } from '@/utils/features'
import { gisMapStore } from "@/store/gismap";
const gismap = gisMapStore();
//  用来存已经创建的组件
const storeComponents = new Map();
const cacheRoutes = ref([])
// 原组件包里面
function formatComponent(component, route) {
  let afterComponent;
  if (component) {
    const path = route.path;
    if (storeComponents.has(path)) {
      afterComponent = storeComponents.get(path);
    } else {
      afterComponent = {
        name: path,
        render() {
          return h(component)
        },
      }
      cacheRoutes.value.push(path)  //进行缓存
      storeComponents.set(path, afterComponent)
    }
    return h(afterComponent);
  }
}
let store = UseTabStore();
if (!store.tabData || store.tabData.tabDataArr.length === 0) {
  store.tabData = {
    active: 0,
    tabDataArr: [
      { id: "af642b71-f902-4a61-9919-589c1f596fc9", title: "首页", path: "/Alllist", key: 0 }
    ]
  }
}
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
watch(cacheRoutes, () => { console.log('activeTabKey', activeTabKey); })
const router = useRouter();
const activeTabKey = computed(() => store.tabData.active);
const tabData = computed(() => store.tabData.tabDataArr)



store.$subscribe(()=>{
  sessionStorage.setItem('activetab',store.tabData.active)  
  sessionStorage.setItem('tabinfo',JSON.stringify(store.tabData.tabDataArr));
})

let addingTab = false;
// let removeTabStatus = ref(false)
// const addTab = (title, path) => {
//   if (title === "首页") return;
//   // const newKey = tabData.value.length;
//   // const newId = generateUUID();
//   // tabData.value.push({ title, path, key: newKey, id: newId });
//   // activeTabKey.value = newKey;

//   addingTab = true;

//   store.tabData.active = activeTabKey.value;
//   store.tabData.tabDataArr = tabData.value;
// };

const removeTab = (targetKey) => {
  // console.log('这是del',cacheRoutes.value);
  cacheRoutes.value = (cacheRoutes.value.filter(iterm =>
    iterm.split('/')[2] !== targetKey
  ));

  // console.log('这是del',cacheRoutes.value);

  if (targetKey === "af642b71-f902-4a61-9919-589c1f596fc9") {
    ElMessage({ message: "首页不能被移除哦~", type: "warning" });
    return;
  } else {
    store.removeTab(router, targetKey)
  }

};

const clickItem = (tab) => {
  // console.log('这是点击', cacheRoutes.value);
  // console.log(activeTabKey.value, tabData.value,gismap.offlineTabInfo)
  const offlineTabInfoList=gismap.offlineTabInfo
  const lastStrPath=tab.props.name  
  const isexists = offlineTabInfoList.some(obj => obj.key === lastStrPath);  
  console.log(isexists)
  if (isexists){
  gismap.setFlage(false)
  gismap.setFlage01(false)
  }else{
    gismap.setFlage(true)
  }

  store.setActiveTab(router, `${tabData.value[tab.index].path}/${tab.props.name}`, tab.props.name)
  addingTab = false
};
// 监听下点击详情的active

// watch(
//   () => route.path,
//   (newPath) => {
//     if(removeTabStatus)return
//     console.log('----------------')
//     console.log('newPath',newPath);
//     console.log('----------------')
//     if (!addingTab) {
//       addTab(route.name, newPath);
//     } else {
//       addingTab = false;
//     }
//   },
//   { deep: true }
// );
// // 去除key相同的
// watch(()=>tabData.value,(newVal)=>{
//   console.log('-------------');
//   console.log(newVal);
//   nextTick(()=>{
//     activeTabKey.value = store.tabData.active
//     tabData.value = store.tabData.tabDataArr
//   })

//   tabData.value.filter(item=>{
//     return item.key === newVal[newVal.length-1].key
//   }

//   )
// },{ deep: true })
let featureSet01=['热图','风污染玫瑰','热图','风污染玫瑰','玫瑰矩阵','玫瑰矩阵','污染日历','Gis空间分布','日变化','相关性拟合图','因子相关矩阵图','相关性矩阵分析','离线数据导入','贡献排名']
// let featureSet01=['热图离线','风污染玫瑰','热图','风污染玫瑰离线','玫瑰矩阵离线','玫瑰矩阵','污染日历','Gis空间分布','日变化','相关性拟合图','相关性矩阵分析','因子相关矩阵图']
const receive = (val) => {
  console.log(userFeature(val.labels,sessionStorage.getItem('features'),featureSet01));
  console.log(val.labels,sessionStorage.getItem('features'),featureSet01);
  
  if (userFeature(val.labels,sessionStorage.getItem('features'),featureSet01)){
  if (val.key === "navadd") {
    const newTabItem = {
      id: generateUUID(),
      key: store.tabData.tabDataArr.length !== 0 ? store.tabData.tabDataArr[store.tabData.tabDataArr.length - 1].key + 1 : 1,
      path: val.title,
      title: val.labels
    };
    
    store.addTab(newTabItem);
    router.push(`${val.title}/${newTabItem.id}`);
  }}
};

//删除所有tabs标签
const removeAllTabs = () => {
  cacheRoutes.value = []
  tabData.value = [{ title: "首页", path: "/Alllist", key: 0 }];
  activeTabKey.value = "af642b71-f902-4a61-9919-589c1f596fc9";
  router.push("/Alllist");
  store.tabData.active = activeTabKey.value;
  store.tabData.tabDataArr = [{ title: "首页", path: "/Alllist", key: 0 }];
};
const rout=useRoute()
onMounted(()=>{
  // console.log(rout.path);
  // console.log(store.tabData);
  if (sessionStorage.getItem('tabinfo')){
    console.log(JSON.parse(sessionStorage.getItem('tabinfo')));
    store.tabData.active=sessionStorage.getItem('activetab')
    store.tabData.tabDataArr=JSON.parse(sessionStorage.getItem('tabinfo'))
  }
  console.log();
  
})




</script>

<style scoped>
.el-aside {
  width: auto;
}

.tabbox {
  position: relative;
  /* margin-right: 320px; */
  padding-right: 20px;
}

.tabbox .close {
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
  font-size: 25px;
  color: #dae0eb;
}
.el-tabs__nav-scroll{
  width: 98% !important;
}
</style>
