<template>
    <div id="NotFound" >
        <!-- <el-page-header layout="back">
  </el-page-header> -->
  <div class="error-page">
    <el-empty description="404页面未找到! " :image-size="300"></el-empty>
    <router-link to="/" class="back">返回到首页</router-link>
  </div>
    </div>
</template>
<style scoped>
.back{
  color: #333;
}
</style>